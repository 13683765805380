import { createSlice } from '@reduxjs/toolkit';
import { mapKeys } from 'lodash-es';
import { createAsyncAction } from '../action/index';
import { storage } from '../../utils/storageService';

export const tvLogin = createAsyncAction('tv-login', 'login');
export const getDoctorClinic = createAsyncAction('selectDoctorClinic', 'getDoctorClinic');

interface User {
  doctors: any;
  doctorsByDocId: any;
  selected: any;
}

interface InitialState {
  user: User;
  value: any;
  accessToken: string;
  formSubmitting: boolean;
  updatedLocalStorage: boolean;
  errors: any;
}

type PropType<T, P extends keyof T> = T[P];
export const counterSlice = createSlice({
  name: 'auth',
  initialState: {
    user: {},
    errors: {},
    accessToken: '',
    formSubmitting: false,
    updatedLocalStorage: false
  } as any,
  reducers: {
    updatedAuthStore: (state, { payload }) => {
      mapKeys(payload, (value, key: keyof InitialState) => state[key] = value);
    },
  },
  extraReducers: {
    [tvLogin.pending as any]: (state, action) => {
      state.formSubmitting = true;
    },
    [tvLogin.fulfilled as any]: (state, { payload }) => {
      // Add user to the state array

      if (payload) {
        const doctorsByDocId: any = {};
        for (let docID in payload.data.doctors) {
          const doctor = payload.data.doctors[docID];
          doctorsByDocId[docID] = {
            doctorID: docID,
            firstName: doctor.firstName,
            lastName: doctor.lastName,
            education: doctor.education,
          }
        }

        const doctorClinics: any = [];
        payload.data.clinics.forEach((clinic: { receptionistDoctors: any[]; clinicID: any; }) => {
          clinic.receptionistDoctors.forEach(receptionistDoctor => {
            receptionistDoctor.doctors.forEach((doctor: { [x: string]: string; doctorID: any; }) => {
              if (doctor['typeOfDoctor'] === "Shared")
                doctorClinics.push({
                  doctorID: doctor.doctorID,
                  clinicID: clinic.clinicID
                });
            });
          });
        });

        state.user.doctorsByDocId = doctorsByDocId;
        state.user.doctorClinics = doctorClinics.length ? doctorClinics : [payload.data.selected];
        state.user.selected = payload.data.selected;
        state.accessToken = payload.token;
        state.formSubmitting = false;

        Promise.all([
          storage.set('user', JSON.stringify(state.user)),
          // storage.set('selectedDoctor', JSON.stringify(state.user.selected)),
          storage.set('accessToken', state.accessToken)]
        );
      }
    },
    [tvLogin.rejected as any]: (state, { payload }) => {
      // Add user to the state array
      state.errors = payload;
      state.formSubmitting = false;
    },

    [getDoctorClinic.fulfilled as any]: (state, { payload }) => {
      // Add user to the state array

      if (payload) {
        state.user = {
          doctors: payload.data.doctors,
          doctorsByDocId: payload.data.doctors,
          selected: payload.data.selected,
        }
        // state.accessToken = payload.token;
      }
    },
  }
})

// Action creators are generated for each case reducer function
export const { updatedAuthStore } = counterSlice.actions
export default counterSlice.reducer;