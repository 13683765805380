
import { combineReducers } from 'redux'
import authorization from './authorization';
import raspberryQueue from './raspberryQueue';
import clinic from './clinic';

const rootReducer = combineReducers({
  authorization: authorization,
  raspberryQueue: raspberryQueue,
  clinic: clinic,
})
export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>