import React from 'react';
import styled from "styled-components";
import Icon from './IconBase';

interface IProps {
  width?: string | number;
  [key: string]: any
}

function NoInternetIcon({ color, ...props }: IProps) {
  if (!(props.width || props.height)) {
    props = Object.assign({ ...props }, { width: 90 });
  }

  // fill="#FC2D1F"
  return (
    <Icon data-icon={'Docon-LogoOut'} viewBox="0 0 90 74" fill={color || 'currentColor'} {...props}>
      <path fillRule="nonzero" d="M81.3,0.5 L85,4.2 L15.7,73.5 L12,69.8 L36.6,45.3 C33.1,46.5 29.9,48.5 27.1,51.3 L18.1,42.3 C26.9,33.5 38.9,29.9 50.3,31.5 L60.8,21 C43.2,15.2 23.1,19.3 9.1,33.3 L0.1,24.3 C19.2,5.2 47.4,0.9 70.7,11.1 L81.3,0.5 Z M37.4,59 C42.4,55.3 49.5,55.7 54,60.2 L45,69.2 L36.1,60.3 L37.4,59 Z M51.7,44.7 L61.5,34.8 C65.3,36.6 68.8,39.1 71.9,42.2 L62.9,51.2 C59.7,48 55.8,45.8 51.7,44.7 Z M80.1,16.3 C83.6,18.6 86.9,21.3 89.9,24.3 L80.9,33.3 C77.8,30.2 74.5,27.6 70.9,25.5 L80.1,16.3 Z"></path>
    </Icon>
  )
}

export default styled(NoInternetIcon)``;