import React from 'react';

const IconBase = ({ style = {}, ...props }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    fill='currentColor'
    preserveAspectRatio='xMidYMid meet'
    {...props}
    style={{
      ...style
    }}
  />
);

export default IconBase;