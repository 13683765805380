import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash-es';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'marquee': any;
    }
  }
}

const Marquee = styled('marquee')`
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 2.5rem !important;
  line-height: 1.3;
`;

const numOfWhiteSpaces = 50;
const DocTicker = () => {
  const { ticker, tickerText } = useSelector(state => state.clinic);
  const gaps = useRef('');

  useEffect(() => {
    for (var i = 0; i < numOfWhiteSpaces; i++) {
      gaps.current += '&nbsp;';
    }
  }, []);

  return (
    <Marquee dangerouslySetInnerHTML={{ __html: isEmpty(ticker) ? tickerText : ticker.join(gaps.current) }} />
  )
}
export default DocTicker;