/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface Iprops {
  className: string;
  showContent: boolean;
  progressMax: number;
  index: number;
  onProgressComplete: Function;
}

const ProgressbarContainer = ({ className, index, showContent, progressMax, onProgressComplete }: Iprops) => {
  const progressInterval = useRef<any>(null);
  const [showCurrent, setShowCurrent] = useState(-1);

  useEffect(() => {
    setShowCurrent(progressMax);
    progressInterval.current = setInterval(() => {
      setShowCurrent(current => current - 1);
    }, 1000);
    return () => {
      clearInterval(progressInterval.current);
    }
  }, [progressMax, showContent]);

  useEffect(() => {
    if (progressMax && !showCurrent) {
      clearInterval(progressInterval.current);
      onProgressComplete();
    }
  }, [showCurrent]);

  if (!showContent) return null;
  return (
    <div className={className}>
      <CircularProgressbar
        value={!showCurrent || showCurrent == progressMax ? 0 : 100}
        text={`${showCurrent}`}
        strokeWidth={6}
        styles={buildStyles({
          pathColor: "#fff",
          textColor: '#fff',
          strokeLinecap: "butt",
          textSize: '2.5rem',
          pathTransition:
            !showCurrent || showCurrent == progressMax ? 'none' : `stroke-dashoffset ${progressMax}s linear`
        })}
      />
    </div>
  )
}

export default React.memo(ProgressbarContainer);