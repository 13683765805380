import React, { useEffect, useState } from "react";
import { currentTime, formatTime } from '../utils';

const DocDate = ({ className }: { className: string }) => {
  const [currTime, setCurrTime] = useState(formatTime(currentTime()));
  useEffect(() => {
    const timeInterval = setInterval(() => {
      setCurrTime(formatTime(currentTime()));
    }, 60000);
    return () => {
      clearInterval(timeInterval);
    }
  }, []);

  return (
    <div className={className}>{currTime}</div>
  )
}
export default DocDate;