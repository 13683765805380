/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { updatedAuthStore } from './redux/reducer/authorization';
import { Icons } from "@docon/docon-ui-library";
import { DoconLogo } from "./shared";
import { storage } from './utils/storageService';
import styled from "styled-components";
import { isEmpty } from 'lodash-es';
import { axiosClientInstance } from '../src/apis';
import { headerKey } from '../src/apis/axiosHelper';
import './App.scss';

const Login = React.lazy(() => import("./pages/Login"));
const RaspberryQueue = React.lazy(() => import("./pages/RaspberryQueue"));

const BASENAME = process.env.REACT_APP_BASENAME;

const RouteMiddleware = ({ component: Component, isValid, isPrivate, ...rest }: any) => {
  const accessToken = useSelector(state => state.authorization.accessToken);
  const showCompo = (isPrivate && (accessToken || isValid)) || (!isPrivate && !(accessToken || isValid));
  useEffect(() => {
    if (accessToken)
      axiosClientInstance.setHeader(headerKey.AccessToken, accessToken);
  }, [accessToken])

  return (
    <Route
      {...rest}
      render={props => (showCompo ? <Component {...props} /> : <Redirect to={isPrivate ? "/tv-login" : "/raspberry-queue"} />)}
    />
  )
}

const Root = styled('div')`
  height: 100vh;
  display: grid;
  place-content: center;
  place-items: center;
  background: #216DE8;
  color: #fff;

  ${Icons.LoadingIcon} {
    margin-top: 24px;
  }
`;

function Loader(): any {
  return (
    <Root>
      <DoconLogo width={250} />
      <Icons.LoadingIcon width={50} />
    </Root>
  );
}

export default function App() {
  const validatorRef = useRef(false);
  const [isMount, setIsMount] = useState(false);
  const dispatch = useDispatch();

  async function validate() {
    if (validatorRef.current) return true;
    const user = await storage.get('user').then((_user: any) => JSON.parse(_user) || {});
    const token = await storage.get('accessToken');

    validatorRef.current = !isEmpty(user);
    if (token)
      dispatch(updatedAuthStore({ user, accessToken: token }));

    return validatorRef.current;
  }

  useEffect(() => {
    const getData = async () => {
      await validate();
      setIsMount(true);
    }
    getData();
  }, []);

  return (
    <Router basename={BASENAME}>
      <Suspense fallback={<Loader />}>
        {isMount ? (
          <Switch>
            <RouteMiddleware path='/tv-login' isValid={validatorRef.current} component={Login} />
            <RouteMiddleware path="/raspberry-queue" isPrivate isValid={validatorRef.current} component={RaspberryQueue} />
            {/* <Route path="/Loader" component={Loader} /> */}
            <Route path="*">
              <Redirect to="/raspberry-queue" />
            </Route>
          </Switch>
        ) : <Loader />}
      </Suspense>
    </Router>
  );
}
