import React from 'react';
import styled from "styled-components";
import Icon from './IconBase';

interface IProps {
  width?: string | number;
  [key: string]: any
}

function LogoutIcon({ color, ...props }: IProps) {
  if (!(props.width || props.height)) {
    props = Object.assign({ ...props }, { width: 48 });
  }

  // fill="#4990E2"
  return (
    <Icon data-icon={'Docon-LogoOut'} viewBox="0 0 48 50" fill={color || 'currentColor'} {...props}>
      <path d="M26.2664165,34.9437148 C26.7354597,35.3541276 27.2631332,35.5300188 27.7908068,35.5300188 C28.4357411,35.5300188 29.0806754,35.2368668 29.5497186,34.7091932 L36.9371482,26.1491557 C37.6993433,25.2696998 37.6993433,23.9798311 36.9371482,23.1003752 L29.5497186,14.5403377 C28.7288931,13.543621 27.2045028,13.4263602 26.2664165,14.3058161 C25.2696998,15.1266417 25.152439,16.6510319 26.0318949,17.5891182 L30.0773921,22.2795497 L15.2439024,22.2795497 C13.9540338,22.2795497 12.8986867,23.3348968 12.8986867,24.6247655 C12.8986867,25.9146341 13.9540338,26.9699812 15.2439024,26.9699812 L30.0773921,26.9699812 L26.0318949,31.6604128 C25.152439,32.6571295 25.2696998,34.1228893 26.2664165,34.9437148 Z" id="Shape"></path>
      <path d="M43.3278612,17.0614447 C43.7969043,18.2340525 45.2040338,18.8203565 46.3766417,18.3513133 C47.5492495,17.8822702 48.1355535,16.4751407 47.6665103,15.3025328 C43.8555347,5.98030019 34.8850844,0 24.8592871,0 C11.2570356,0 0.234521576,11.0225141 0.234521576,24.6247655 C0.234521576,38.2270169 11.2570356,49.249531 24.8592871,49.249531 C34.8850844,49.249531 43.8555347,43.2692308 47.6665103,33.9469981 C48.1355535,32.7743902 47.6078799,31.3672608 46.3766417,30.8982176 C45.2040338,30.4291745 43.7969043,30.956848 43.3278612,32.1880863 C40.2204503,39.6927767 33.0089118,44.5590994 24.8592871,44.5590994 C13.8954034,44.5590994 4.9249531,35.5886492 4.9249531,24.6247655 C4.9249531,13.6608818 13.8954034,4.69043152 24.8592871,4.69043152 C33.0089118,4.69043152 40.2204503,9.55675422 43.3278612,17.0614447 Z" id="Shape"></path>
    </Icon>
  )
}

export default styled(LogoutIcon)``;