import React from "react";
import styled from "styled-components";
import { BaseBox } from '@docon/docon-ui-library';
interface IProps {
  src: string;
  alt?: string;
  [key: string]: any;
}
const ImageStyled = styled(BaseBox)``;
const assetsPath = process.env.PUBLIC_URL || '';

function Image({ src, ...props }: IProps) {
  return (
    <ImageStyled as="img" src={`${assetsPath}${src}`} loading="lazy" {...props} />
  )
}
export default styled(Image)``;