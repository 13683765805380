import React, { useEffect, useState } from "react";
import { getCurrDate } from '../utils';

const DocDate = ({ className }: { className: string }) => {
  const [currDate, setCurrDate] = useState(getCurrDate());
  useEffect(() => {
    const dateInterval = setInterval(() => {
      setCurrDate(getCurrDate());
    }, 60000);
    return () => {
      clearInterval(dateInterval);
    }
  }, []);

  return (
    <div className={className}>{currDate}</div>
  )
}
export default DocDate;