import { createSlice } from '@reduxjs/toolkit';
import { createAsyncAction } from '../action/index';
import { get } from 'lodash-es';

export const getClinic = createAsyncAction('getClinic', 'getClinicContent');

function populateAdType(data: any, adType: string, adsFlagByFileName: any) {
  let ads: any = [];
  let flagByFilename: any = {};
  (data[adType] || []).forEach((ad: { filename: any; }) => {
    if (ad.filename && !adsFlagByFileName[ad.filename]) {
      flagByFilename[ad.filename] = true;
      ads.push({ ...ad });
    }
  });
  return { ads, flagByFilename };
}

interface InitialState {
  showAds: any[],
  brands: any[],
  ticker: any[],
  tickerText: string,
  adsFlagByFileName: any;
  defaultLG: string;
  fetchingBoards: boolean;
}

const initialState = {
  showAds: [],
  brands: [],
  ticker: [],
  tickerText: "Find your digital prescription online at docon.co.in",
  adsFlagByFileName: {},
  defaultLG: 'linear-gradient(162.41deg, #0089FF 0%, #3DB7FA 46.6%, #42BAFA 100%)', //Sky Blue
  fetchAdContentDefaultTime: 300000,
  fetchingBoards: false,
} as InitialState;

export const counterSlice = createSlice({
  name: 'clinic',
  initialState,
  reducers: {},
  extraReducers: {
    [getClinic.pending as any]: (state, action) => {
      state.fetchingBoards = true;
    },
    [getClinic.fulfilled as any]: (state, { payload, meta }) => {
      let msg = get(payload, 'messages[0]');
      const { ads, flagByFilename } = populateAdType(msg, 'boards', state.adsFlagByFileName);
      state.showAds.push(...ads);
      state.brands = get(payload, 'messages[0].brands', []);
      state.adsFlagByFileName = { ...state.adsFlagByFileName, flagByFilename };
      state.ticker = msg['ticker'];
      state.fetchingBoards = false;
    },
    [getClinic.rejected as any]: (state, { payload }) => {
      state.fetchingBoards = false;
    },
  }
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount, } = counterSlice.actions
export default counterSlice.reducer;