import { createAsyncThunk } from '@reduxjs/toolkit';
import apis, { Apis } from '../../apis/index';
import { storage } from '../../utils/storageService';
const BASENAME = process.env.REACT_APP_BASENAME || '/';

export const createAsyncAction = (type: string, fuName: keyof Apis) => createAsyncThunk<any, any>(
  type,
  async (payload, thunkAPI) => {
    const { error, data } = await apis[fuName](payload);

    if (error?.accessToken)
      storage.clear().then(() => window.location.href = BASENAME);

    if (error) {
      return thunkAPI.rejectWithValue(error)
    }

    return data;
  }
);