import localforage from 'localforage';
export interface StorageConfig {
  name: string;
  version: number,
  size: number;
  description: string;
  storeName: string;
  driver?: string;
}

export interface StorageServiceInterface {
  store: LocalForage;
  get<T>(key: string): Promise<T | null>
  set<T>(key: string, value: T): Promise<T>
  remove(key: string): Promise<void>
}

export class StorageService implements StorageServiceInterface {
  store: LocalForage;
  constructor({
    name,
    version,
    size,
    description,
    storeName,
    driver = localforage.LOCALSTORAGE
  }: StorageConfig) {
    this.store = localforage.createInstance({ driver, name, version, size, description, storeName });
  }

  // implements LocalForageDbMethodsCore
  get = <T>(key: string): Promise<T | null> => this.store.getItem<T>(key);
  set = <T>(key: string, value: T): Promise<T> => this.store.setItem<T>(key, value);
  remove = (key: string): Promise<void> => this.store.removeItem(key);
  clear = (): Promise<void> => this.store.clear();
  length = (): Promise<number> => this.store.length();
  // key = (keyIndex: number): Promise<string> => this.store.key(keyIndex);
  keys = (): Promise<string[]> => this.store.keys();
  // iterate = <T, U>(iteratee: (value: T, key: string, iterationNumber: number) => U): Promise<U> =>
  // this.store.iterate<T, U>(iteratee)
}

// const tvlocalforage = new StorageService({
//   driver: localforage.INDEXEDDB,
//   name: 'TvReact',
//   version: 1.0,
//   size: 10737418240, //10GB
//   storeName: 'docon-Tv',
//   description: 'docon Tv localstorage'
// });

const storage = new StorageService({
  driver: localforage.LOCALSTORAGE, // Force WebSQL; same as using setDriver()
  name: 'myApp',
  version: 1.0,
  size: 4980736, // Size of database, in bytes. WebSQL-only for now.
  // storeName: environment.storeName || 'docon', // Should be alphanumeric, with underscores.
  storeName: 'docon', // Should be alphanumeric, with underscores.
  description: 'docon localstorage'
});

export {
  storage
}