/* eslint-disable eqeqeq */
import moment from "moment";
import { createObjectURL, imgSrcToBlob } from 'blob-util';

function formatTime(date: any) {
  let h: any = moment.duration(date, 'seconds').get('hour');
  let m: any = moment.duration(date, 'seconds').get('minutes');
  let AMorPM = 'PM';

  AMorPM = h < 12 ? 'AM' : 'PM';
  AMorPM = h == 24 ? 'AM' : AMorPM;

  m = m < 10 ? '0' + m : m;
  h = h > 12 ? h - 12 : h;
  h = h < 10 ? '0' + h : h;

  return h + ':' + m + ' ' + AMorPM;
}

function formatETS(date: any) {
  let h: any = moment.duration(date, 'seconds').get('hour');
  let m: any = moment.duration(date, 'seconds').get('minutes');

  let time = (h == 0 ? '' : h + ' hr ') + (m == 0 ? '' : ((m < 10 ? '0' : '') + m + ' min'));
  return time;
}

function currentTime() {
  let mom = moment().utcOffset("+05:30");
  let now = (mom.hours() * 60 * 60) + (mom.minutes() * 60) + mom.seconds();
  return now;
}

function getCurrDate() {
  return moment(moment().utcOffset("+05:30").toDate()).format('ddd, D MMM');
}

async function imgSrcBlobPromise(url: string) {
  const blob = await imgSrcToBlob(url, undefined, 'Anonymous', 1).then((blob) => blob);
  let blobURL = createObjectURL(blob);
  return 'url(' + blobURL + ')';
}

export {
  formatTime,
  formatETS,
  currentTime,
  getCurrDate,
  imgSrcBlobPromise
}