import React from 'react';
import styled from 'styled-components';
import Icon from './IconBase';

function DoconLogo(props: any) {
  if (!(props.width || props.height)) {
    props = Object.assign({ ...props }, { width: 116 });
  }

  return (
    <Icon
      data-icon={'DoconLogo'}
      viewBox='0 0 912 235'
      {...props}>
      <defs>
        <polygon id='path-1' points='0.354636735 0.312948837 17.5077347 0.312948837 17.5077347 65.4377674 0.354636735 65.4377674'></polygon>
        <polygon id='path-3' points='0.435153846 0.156470588 23.9791538 0.156470588 23.9791538 68.8552941 0.435153846 68.8552941'></polygon>
        <polygon id='path-5' points='0.460416667 0.650684211 23.4878435 0.650684211 23.4878435 76.7181211 0.460416667 76.7181211'></polygon>
      </defs>
      <g id='Config' stroke='none' strokeWidth='1'
        fill='none' fillRule='evenodd'>
        <g id='Identity-Copy' transform='translate(-56.000000, -395.000000)'>
          <g id='Group' transform='translate(56.000000, 395.000000)'>
            <g id='Group-36'>
              <path d='M117.837452,233.911502 C100.144425,233.911502 81.8721447,235.323361 64.2492061,233.559572 C30.1371009,230.149993 2.82144298,201.405607 1.11253947,167.187677 C0.341574561,151.769011 1.02183772,136.174379 1.02183772,120.747432 L1.02183772,53.2058877 C1.02183772,47.9352211 0.694074561,42.4699579 1.35166228,37.2241333 C3.4790307,20.2300632 16.2123202,5.83406316 32.8189868,1.62953684 C40.2400395,-0.250182456 48.2362237,0.339817544 55.8366184,0.339817544 L91.1999956,0.339817544 C113.962013,0.339817544 136.350917,-0.537936842 158.15232,7.48606316 C189.485654,19.0190105 214.921311,44.063993 226.90425,75.2242737 C252.121399,140.78466 211.862189,215.859572 143.293724,231.127116 C134.945039,232.986133 126.390215,233.911502 117.837452,233.911502' id='Fill-33' fill='#0089FF'></path>
              <path d='M108.879211,174.764105 C83.6641228,174.764105 60.9763158,157.838351 53.7098684,133.602807 C52.5142544,129.615649 54.7632456,125.413193 58.7335088,124.212491 C62.7037719,123.011789 66.8884211,125.270351 68.0840351,129.257509 C73.4539912,147.174877 90.2317544,159.691158 108.879211,159.691158 C127.526667,159.691158 144.30443,147.174877 149.676447,129.257509 C150.872061,125.270351 155.056711,123.01386 159.026974,124.212491 C162.995175,125.413193 165.244167,129.615649 164.050614,133.602807 C156.782105,157.838351 134.094298,174.764105 108.879211,174.764105' id='Fill-35' fill='#FFFFFF'></path>
            </g>
            <g id='Group-43' transform='translate(59.000000, 57.000000)'>
              <g id='Group-39' transform='translate(41.224490, 111.767442)'>
                <mask id='mask-2' fill='white'>
                  <use xlinkHref='#path-1'></use>
                </mask>
                <g id='Clip-38'></g>
                <polygon id='Fill-37' fillOpacity='0.6' fill='#FFFFFF'
                  mask='url(#mask-2)' points='17.5081469 65.1442744 0.354636735 65.4381814 0.354636735 0.312948837 17.5081469 0.312948837'></polygon>
              </g>
              <path d='M15.6421204,20.4685512 L7.73938571,20.4685512 C3.5386102,20.4685512 0.133467347,17.0492953 0.133467347,12.8311093 L0.133467347,8.56117907 C0.133467347,4.34092326 3.5386102,0.921667442 7.73938571,0.921667442 L15.6421204,0.921667442 C19.8428959,0.921667442 23.2480388,4.34092326 23.2480388,8.56117907 L23.2480388,12.8311093 C23.2480388,17.0492953 19.8428959,20.4685512 15.6421204,20.4685512' id='Fill-40' fill='#FFFFFF'></path>
              <path d='M91.7604612,20.4685512 L83.8597878,20.4685512 C79.656951,20.4685512 76.2518082,17.0492953 76.2518082,12.8311093 L76.2518082,8.56117907 C76.2518082,4.34092326 79.656951,0.921667442 83.8597878,0.921667442 L91.7604612,0.921667442 C95.963298,0.921667442 99.3684408,4.34092326 99.3684408,8.56117907 L99.3684408,12.8311093 C99.3684408,17.0492953 95.963298,20.4685512 91.7604612,20.4685512' id='Fill-42' fill='#FFFFFF'></path>
            </g>
            <g id='Group-46' transform='translate(51.000000, 65.000000)'>
              <mask id='mask-4' fill='white'>
                <use xlinkHref='#path-3'></use>
              </mask>
              <g id='Clip-45'></g>
              <path d='M6.38408462,68.8552941 L0.477315385,14.3726471 C-0.0959153846,7.64441176 5.10677692,1.69235294 12.0748538,1.10764706 L23.4023923,0.156470588 L23.9797769,6.90529412 L12.6543154,7.85647059 C9.53893077,8.11794118 7.2107,10.7779412 7.46823846,13.7858824 L13.3729308,68.2685294 L6.38408462,68.8552941 Z' id='Fill-44' fillOpacity='0.3'
                fill='#FFFFFF' mask='url(#mask-4)'></path>
            </g>
            <g id='Group-49' transform='translate(144.000000, 64.000000)'>
              <mask id='mask-6' fill='white'>
                <use xlinkHref='#path-5'></use>
              </mask>
              <g id='Clip-48'></g>
              <path d='M16.3364583,76.7181211 L23.4239583,15.3995684 C24.115625,8.57230526 19.1114583,2.45812105 12.2697917,1.76791053 L1.153125,0.649436842 L0.459375,7.49748947 L11.578125,8.61596316 C14.6364583,8.92572632 16.871875,11.6574632 16.5635417,14.7093579 L9.47604167,76.0279105 L16.3364583,76.7181211 Z' id='Fill-47' fillOpacity='0.3'
                fill='#FFFFFF' mask='url(#mask-6)'></path>
            </g>
            <path d='M458.726871,174.945604 C443.794638,174.945604 433.84119,163.037963 433.84119,148.156011 C433.84119,133.731325 443.794638,122.054396 458.726871,122.054396 C473.432233,122.054396 483.385681,133.731325 483.385681,148.156011 C483.385681,163.037963 473.432233,174.945604 458.726871,174.945604 M458.5,90 C423.885681,90 399,114.499103 399,147.927377 C399,181.814997 423.885681,207 458.5,207 C493.114319,207 518,181.814997 518,147.927377 C518,114.499103 493.114319,90 458.5,90' id='Fill-50' fill='currentColor'></path>
            <path d='M328.764045,174.662144 C313.824582,174.662144 303.862875,162.79114 303.862875,147.954976 C303.862875,133.574671 313.824582,121.93367 328.764045,121.93367 C343.47856,121.93367 353.440266,133.574671 353.440266,147.954976 C353.440266,162.79114 343.47856,174.662144 328.764045,174.662144 M378.417794,31 L356.961018,31 C352.771612,31 349.376748,34.4085881 349.376748,38.6149308 L349.376748,93.2165744 C342.981197,91.113403 335.985095,89.9778977 328.539097,89.9778977 C293.901169,89.9778977 269,114.401623 269,147.727046 C269,181.510401 293.901169,206.617916 328.539097,206.617916 C336.203852,206.617916 343.381564,205.368446 349.929833,203.087075 C351.017427,205.948631 353.729191,208 356.961018,208 L378.417794,208 C382.605136,208 386,204.58934 386,200.385069 L386,38.6149308 C386,34.4085881 382.605136,31 378.417794,31' id='Fill-52' fill='currentColor'></path>
            <path d='M721.725836,174.945604 C706.795924,174.945604 696.840586,163.037963 696.840586,148.156011 C696.840586,133.731325 706.795924,122.054396 721.725836,122.054396 C736.433006,122.054396 746.386281,133.731325 746.386281,148.156011 C746.386281,163.037963 736.433006,174.945604 721.725836,174.945604 M721.501031,90 C686.885249,90 662,114.499103 662,147.927377 C662,181.814997 686.885249,207 721.501031,207 C756.114751,207 781,181.814997 781,147.927377 C781,114.499103 756.114751,90 721.501031,90' id='Fill-53' fill='currentColor'></path>
            <path d='M639.881035,159.123857 L620.356142,159.123857 C617.229431,159.123857 614.534632,161.079978 613.261629,163.937651 C609.924129,171.427155 602.331575,176.114814 592.641458,176.114814 C576.590459,176.114814 566.819746,164.245751 566.819746,148.884204 C566.819746,133.522658 576.590459,121.885186 592.641458,121.885186 C602.331575,121.885186 609.924129,126.572845 613.261629,134.060281 C614.534632,136.917955 617.229431,138.876143 620.356142,138.876143 L641.732675,138.876143 C646.485772,138.876143 649.961732,134.370448 648.761058,129.767567 C642.497304,105.731679 620.434671,90 592.641458,90 C557.0511,90 531,114.670697 531,148.884204 C531,183.329303 557.0511,208 592.641458,208 C619.591513,208 641.149905,193.209161 648.147289,170.399467 C649.862537,164.804051 645.731476,159.123857 639.881035,159.123857' id='Fill-54' fill='currentColor'></path>
            <path d='M861.744895,90 C851.995746,90 843.695573,92.9789825 836.065267,97.8687368 C833.270373,99.6573684 829.607744,97.5830526 829.607744,94.2542105 C829.607744,91.9045614 827.711503,90 825.376242,90 L804.808218,90 C798.2868,90 793,95.31 793,101.860035 L793,200.365193 C793,204.58214 796.402927,208 800.60145,208 L825.052645,208 C829.253229,208 832.660278,204.58007 832.660278,200.361053 L832.660278,142.093895 C832.660278,135.301649 842.225986,125.40207 854.254746,125.420702 C866.971923,125.445544 875.368969,133.366035 875.368969,149.981263 L875.368969,200.280316 C875.368969,204.542807 878.811058,208 883.054926,208 L904.44328,208 C908.604703,208 911.978774,204.609053 911.978774,200.431439 L911.978774,138.338596 C912.768187,114.918702 891.456095,90 861.744895,90' id='Fill-55' fill='currentColor'></path>
          </g>
        </g>
      </g>
    </Icon>
  );
}

export default styled(DoconLogo)``;