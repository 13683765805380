import axios, { AxiosInstance } from "axios";
import { get } from 'lodash-es';
type Method = "get" | "post";

export interface InstanceConfig {
  baseURL: string;
  retries: number;
  retriesDelay?: number;
  headers?: any;
  timeout?: number;
}

export interface Request {
  url: string;
  params?: any | undefined;
  data?: any;
}

export interface axiosClientInterface {
  instance: AxiosInstance;
  setHeader(key: string, value: string | undefined): void;
  makePostRequest(requestObject: Request): any;
  makeGetRequest(requestObject: Request): any;
}

function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default class axiosClient implements axiosClientInterface {
  instance: AxiosInstance;
  retries: number;
  retriesDelay: number;

  constructor({
    baseURL,
    headers,
    retries,
    retriesDelay,
    timeout = 10000
  }: InstanceConfig) {
    this.instance = axios.create({ baseURL, headers });
    this.retriesDelay = retriesDelay || 2;
    this.retries = retries || 2;
  }

  setHeader(key: string, value: string | undefined) {
    this.instance.defaults.headers.common[key] = value || "";
  }

  makePostRequest(requestObject: Request) {
    return this.apiRequest(requestObject, 'post');
  }

  makeGetRequest(requestObject: Request) {
    return this.apiRequest(requestObject, 'get');
    // console.log('instance', this.instance.defaults.headers)
    // return this.instance.get(requestObject.url, requestObject.data);
  }

  private async apiRequest(requestObject: Request, methodType: Method) {
    try {
      return await this.makeRequest(requestObject, methodType);
    } catch (error) {
      console.log('error', error);
      return { error: get(error, 'response.data.errors', error.message) };
    }
  }

  private async makeRequest(
    { url, data }: Request,
    type: Method = 'get',
    activeRetryCount?: number
  ): Promise<any> {
    let retryCount = Number(activeRetryCount) || 0;
    if (retryCount > this.retries) {
      throw Error(`Unable to make the request (total retry: ${retryCount})`);
    }

    try {
      if (type === 'get')
        return this.instance.get(url, data);
      else
        return this.instance[type](url, data);
    } catch (e) {
      retryCount++;
      await delay(1000 * this.retriesDelay ** retryCount);
      return await this.makeRequest({ url, data }, type, retryCount);
    }
  }
}

export const headerKey = {
  AccessToken: "access-token",
  Authorization: "authorization",
  Admin: "admin",
  AdminID: "adminId",
  ContentType: "Content-Type",
};
