import axiosClient from "./axiosHelper";

const baseURL = process.env.REACT_APP_BASE_URL || 'https://alpha.docon.co.in';
export const axiosClientInstance = new axiosClient({ baseURL, retries: 3, headers: {} });
const API_V1 = 'api/v1';
const API_V2 = 'api/v2';
const DOCONMW = 'doconmw';

export default {
  login: (data: any) => {
    return axiosClientInstance.makePostRequest({
      url: `${API_V1}/reception/tv-login`,
      data: { data },
    });
  },
  getAppointment: ({ params }: any) => {
    return axiosClientInstance.makeGetRequest({
      url: `/${DOCONMW}/${API_V2}/appointment`,
      data: { params }
    });
  },
  getAppointmentShift: ({ params }: any) => {
    return axiosClientInstance.makeGetRequest({
      url: `/${DOCONMW}/${API_V2}/shift`,
      data: { params }
    });
  },
  getClinicContent: ({ params }: any) => {
    return axiosClientInstance.makeGetRequest({
      url: `${API_V1}/clinic/${params.clinicID}/content`,
    });
  },
  getDoctorClinic: ({ params }: any) => {
    return axiosClientInstance.makePostRequest({
      url: `${API_V1}/reception/select-doctor-clinic`,
      data: params
    });
  }
} as Apis;

export interface Apis {
  login: Function,
  getAppointment: Function,
  getAppointmentShift: Function,
  getClinicContent: Function,
  getDoctorClinic: Function
}