import { createSlice } from '@reduxjs/toolkit';
import { createAsyncAction } from '../action/index';
import { get, isEmpty, mapKeys } from 'lodash-es';

export const getAppointment = createAsyncAction('getAppointment', 'getAppointment');
export const getAppointmentShift = createAsyncAction('getAppointmentShift', 'getAppointmentShift');

function parseAppointmentFromLoadQueue(appointment: any) {
  if (!isEmpty(appointment.tmpUserDetails)) {
    appointment.userDetails = appointment.tmpUserDetails;
  }

  const key = appointment.patientID ? 'patientDetails' : 'userDetails';
  appointment.patientUser = {
    firstName: appointment[key].firstName,
    lastName: appointment[key].lastName,
    mobileNumber: appointment[key].mobileNumber,
  }
  return appointment;
}

function filterPatients(patients: any[]) {
  let appointments: any = [];
  let patientsQ: any = [];
  patients.forEach((patient) => {
    if (patient.ETSText !== 'Not Arrived') {
      if (!isEmpty(appointments)) {
        patientsQ = [...patientsQ, ...appointments];
        appointments = [];
      }
      patientsQ.push(patient);
    } else {
      appointments.push(patient);
    }
  });

  if (!isEmpty(appointments)) patientsQ = [...patientsQ, ...appointments];

  return patientsQ;
}

interface InitialState {
  patients: any;
  shiftDetails: any;
  docNumbering: any;
  docIds: any;
  totalCards: any;
  currentCard: any;
  allDocsLoaded: any;
  docLoaded: any;
  loadAssetsComplete: any;
  docsFilteredOut: any;
  showError: any;
  GAIntervalTime: number;
  showErrorText: String;
  noPQText: String;
  boardLinearGradient: String;
  noIntText: String;
  cardShuffleTime: any;
  fetchPatientsDefaultTime: any;
  errorStateRetryIntervalTime: any;
}

const initialState = {
  patients: {},

  shiftDetails: {},
  docNumbering: {},
  docIds: [],
  totalCards: 0,
  currentCard: 0,
  allDocsLoaded: 0,
  docLoaded: {},
  loadAssetsComplete: 1,
  docsFilteredOut: {},
  showError: 0,
  showErrorText: 'Something went wrong. Retrying !',
  noPQText: "NO PATIENTS IN QUEUE",
  boardLinearGradient: 'linear-gradient(162.41deg, #0089FF 0%, #3DB7FA 46.6%, #42BAFA 100%)',
  noIntText: 'No Internet',

  GAIntervalTime: 60000,
  cardShuffleTime: 10000,
  fetchPatientsDefaultTime: 60000,
  errorStateRetryIntervalTime: 10000,
} as InitialState;

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    updatedStore: (state: { [x: string]: any; }, { payload }: any) => {
      mapKeys(payload, (value, key: keyof InitialState) => state[key] = value);
    },
  },
  extraReducers: {
    [getAppointment.pending as any]: (state, { payload, meta }) => {
      state.docsFilteredOut = {};
      state.docLoaded = {};
      state.allDocsLoaded = 0;
      state.currentCard = 0;

    },
    [getAppointment.fulfilled as any]: (state, { payload, meta }) => {
      const patientsByDocId: any = {};
      state.patients = {}
      payload.appointment.forEach((apt: any) => {
        patientsByDocId[apt.doctorID] = patientsByDocId[apt.doctorID] || [];
        let parsedApt = parseAppointmentFromLoadQueue(apt);
        let patientDetails = parsedApt['patientUser'];

        if (isEmpty(patientDetails)) { return null; }

        const num = get(patientDetails, 'mobileNumber', '').toString();
        let name = `${patientDetails.firstName || ''} ${patientDetails.lastName || ''}`.toLowerCase();
        let actions = parsedApt['actions'];

        patientsByDocId[apt.doctorID].push({
          pNum: isEmpty(num) ? "" : num.substr(0, 3) + "****" + num.substr(7, 4),
          pName: name.length > 17 ? name.substr(0, 14) + '...' : name,
          ETSText: actions.inConsult ? 'IN' : (actions.markArrived ? 'Not Arrived' : ''),
          ETSClass: actions.inConsult ? 'green' : 'blank'
        });
        return parsedApt;
      });

      state.docIds = [];
      mapKeys(patientsByDocId, (patients, docID: string) => {
        state.docIds.push(docID);
        const patientsQ = filterPatients(patients);

        state.patients[docID] = { patientsQ, showQ: [] };
        state.docLoaded[docID] = 1;
      });
      state.allDocsLoaded = 1; //checkAllDocsLoaded(state.doctorData, state.docLoaded);
      if (state.allDocsLoaded) {
        state.showError = 0;
      }
    },
    [getAppointment.rejected as any]: (state, { payload }) => {
      // Add user to the state array
      state.showError = 1;
      // state.errors = payload;
      // state.entities.push(action.payload)
    },

    [getAppointmentShift.pending as any]: (state, { meta }) => {
      const docId: string = JSON.parse(get(meta, 'arg.params.docClinics', '[]'))?.['0']?.doctorID;
      state.shiftDetails[docId] = [];
    },
    [getAppointmentShift.fulfilled as any]: (state, { payload, meta }) => {
      const docId: string = JSON.parse(get(meta, 'arg.params.docClinics', '[]'))?.['0']?.doctorID;
      state.shiftDetails[docId] = payload.shifts || [];
    },
  }
})

// Action creators are generated for each case reducer function
export const { updatedStore, } = counterSlice.actions
export default counterSlice.reducer;